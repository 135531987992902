import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../layout/layout"
import Button from "../../components/button"
import CustomContent from "./customContent/customContent"
import DowForm from "./customContent/dowForm"
import crossIcon from "../../images/cross.svg"

const SubjectList = ({ subjectList, currentSlug }) => {
  return (
    <div className="subject-list">
      <p className="subject-label">Subjects: </p>
      {subjectList &&
        subjectList.map((subject, idx) => (
          <Link
            key={idx}
            to={`/subject/${subject.slug}`}
            className={`subject-item-container + ${
              subject.slug === currentSlug ? " subject-item-active" : ""
            }`}
          >
            {subject.label}
          </Link>
        ))}
    </div>
  )
}

const SubjectContent = (props) => {
  const subject = props.subject

  return (
    <div>
      {subject && subject.description && (
        <div className="subject-description">
          {subject && `${subject.description}`}
        </div>
      )}

      {subject && subject.descriptionParagraphs && (
        <div>
          {subject.descriptionParagraphs.map((description) => {
            if (subject.descriptionParagraphs) {
              return (
                <div className="subject-description-custom" key={description}>
                  {description}
                </div>
              )
            } else {
              return <></>
            }
          })}
        </div>
      )}

      {subject && subject.subSection1 && (
        <div className="subject-topics-custom">
          {subject && (
            <p>
              <strong>Available topics: </strong>
              {" " + subject.subSection1.join(", ")}
            </p>
          )}
        </div>
      )}

      {subject && subject.italic1 && (
        <p style={{ fontStyle: "italic", marginBottom: 60 + "px" }}>
          {subject.italic1}
        </p>
      )}

      {subject && subject.topics && (
        <div className="subject-topics">
          {subject && (
            <p>
              <strong>Available topics: </strong>
              {" " + subject.topics.join(", ")}
            </p>
          )}
        </div>
      )}

      {subject && subject.unitStandards && (
        <div>
          <h6>{subject.label}-related unit standards</h6>
          <div className="subject-unit-standard-group-wrapper">
            {subject.unitStandards.map((unitStandard, idx) => (
              <div key={idx} className="subject-unit-standard">
                <div className="unit-standard-label-container">
                  <span>
                    <strong>{unitStandard.topic}</strong>
                  </span>
                </div>
                <div>
                  {unitStandard.units.map((unit, unitIdx) => {
                    if (unit.slug) {
                      return (
                        <Link to={unit.slug}>
                          <div key={idx}>
                            <span>
                              <strong>&#8226;&#xa0;{unit.id}</strong>{" "}
                              {unit.id ? `- ` : ``}
                              {unit.description}
                            </span>
                          </div>
                        </Link>
                      )
                    } else if (unit.description) {
                      return (
                        <div key={idx}>
                          <span>
                            <strong>&#8226;&#xa0;{unit.id}</strong>{" "}
                            {unit.id ? `- ` : ``}
                            {unit.description}
                          </span>
                        </div>
                      )
                    } else if (unit.groups) {
                      return (
                        <div key={idx}>
                          <span>
                            <strong>
                              &#8226;&#xa0;
                              {unit.groups.slice(0, -1).join(", ") +
                                " and " +
                                unit.groups.slice(-1)}
                            </strong>
                          </span>
                        </div>
                      )
                    } else {
                      return <></>
                    }
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {subject && subject.additionalInfo && (
        <div className="subject-additional-info-container">
          <h6>Additional Info</h6>
          <div className="subject-additional-info">
            {subject.additionalInfo.map((p) => (
              <>
                <p>{p}</p>
                <br />
              </>
            ))}
          </div>
        </div>
      )}

      {subject && subject.qualifications && subject.id !== 2 && (
        <div className="subject-qualifications">
          <h6>Qualifications available:</h6>
          {subject.qualifications.map((q, idx) => (
            <Link
              key={idx}
              to={`/subject/${subject.slug}/${q.slug}`}
              className="qualification-link"
            >
              {q.name}
            </Link>
          ))}
        </div>
      )}

      {subject && subject.subSection3Line1 && (
        <p className="subject-additional-info">{subject.subSection3Line1}</p>
      )}

      {subject && subject.subSection3Line2 && (
        <p className="subject-additional-info">
          <strong>{subject.subSection3Line2Bold}</strong>
          {subject.subSection3Line2}
        </p>
      )}

      {subject && subject.enableDownload && (
        <div class="subject-download-container">
          <h6>Single unit courses: </h6>
          <a href="/course-list.pdf" download>
            <Button colorSchema="primOutline" className="subject-download-btn">
              Download full list
            </Button>
          </a>
        </div>
      )}
      <div>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a>
        <Link to="/contact">
          <Button style={{ marginTop: "35px" }}>Contact us</Button>
        </Link>
        
      </div>
    </div>
  )
}

const CustomModal = (props) => {
  const [showClose, setShowClose] = useState(false)
  const [loading, setLoading] = useState(true)

  const enableClose = () => {
    setShowClose(true)
  }

  const loaded = () => {
    setLoading(false)
  }

  useEffect(() => {
    setTimeout(enableClose, 12000)
  }, [])

  return (
    <div
      className={`custom-modal-background ${
        props.transition ? "custom-modal-hide" : null
      }`}
      onClick={props.modalState}
    >
      <div
        className={`custom-modal-container ${
          props.transition ? "custom-modal-hide" : null
        }`}
      >
        <img
          src={crossIcon}
          onClick={props.modalState}
          className="custom-modal-container-close"
          alt="close menu"
        />

        <DowForm loading={loading} loaded={loaded} />

        {showClose && loading === false ? (
          <button
            onClick={props.modalState}
            style={{ marginTop: "50px" }}
            className="dow-btn"
          >
            Close
          </button>
        ) : null}
      </div>
    </div>
  )
}

const SubjectPage = ({ pageContext }) => {
  const [modal, setModal] = useState(false)
  const [transition, setTransition] = useState(false)

  const hideModalTransition = () => {
    setModal(false)
  }

  const modalState = () => {
    if (modal === false) {
      setModal(true)
      setTransition(false)
    } else if (modal === true) {
      setTransition(true)
      setTimeout(hideModalTransition, 1200)
    }

    modal
      ? (document.body.style.overflowY = "scroll")
      : (document.body.style.overflowY = "hidden")
  }

  const subject = pageContext.subject
  const subjectList = pageContext.subjectList
  const customContent = subject !== undefined ? subject.customContent : ""

  return (
    <div>
      {customContent && modal ? (
        <CustomModal
          modal={modal}
          modalState={modalState}
          transition={transition}
        />
      ) : null}

      <Layout
        title={subject !== undefined ? subject.title : ""}
        description={subject !== undefined ? subject.metaContent : ""}
      >
        {subject && (
          <div
            className="layout-wrapper subject-page-heading-wrapper"
            style={{ backgroundImage: `url(${subject.backgroundImage})` }}
          >
            <h3 className="subject-page-heading">{subject.label}</h3>
          </div>
        )}
        <div className="layout-container subject-page-content-container">
          {subjectList && (
            <SubjectList
              subjectList={subjectList}
              currentSlug={subject.slug}
            ></SubjectList>
          )}
          {customContent ? (
            <CustomContent subject={subject} modalState={modalState} />
          ) : (
            <SubjectContent subject={subject}></SubjectContent>
          )}
        </div>
      </Layout>
    </div>
  )
}

export default SubjectPage
